<template>
  <div>
    <img :src="url">
  </div>
</template>
<script>
export default {
  props: {
    src: {
      type: null,
      default: null
    }
  }, // 父组件传过来所需的url
  data() {
    return {
      url: require('@/assets/images/default.png') // 先加载loading.gif
    }
  },
  watch: {
    src(n, o) {
      this.url = require('@/assets/images/default.png')
      var newImg = new Image()
      newImg.src = n
      newImg.onerror = () => {
        // 图片加载错误时的替换图片
        newImg.src = require('@/assets/images/default.png')
      }
      newImg.onload = () => {
        // 图片加载成功后把地址给原来的img
        this.url = newImg.src
      }
    }
  },
  mounted() {
    var newImg = new Image()
    newImg.src = this.src
    newImg.onerror = () => {
      // 图片加载错误时的替换图片
      newImg.src = require('@/assets/images/default.png')
    }
    newImg.onload = () => {
      // 图片加载成功后把地址给原来的img
      this.url = newImg.src
    }
  }
}
</script>
