<template>
  <div class="koala">
    <search :search-out="searchOut" />
    <treeSelect class="treeSelect" :options="pddOptions">
      <div class="right_box_all">
        <div v-if="brand.length>0" class="titlebox">
          <h1>品牌推荐</h1>
          <div v-for="ele in brand" :key="ele.brandImg" class="imgbox" @click="golists(ele)">
            <img :src="ele.brandImg">
          </div>
        </div>
        <div v-for="item in pddOptions.list" :key="item.title" class="dtalbox">
          <h1>{{ item.title }}</h1>
          <div class="div">
            <div v-for="dtal in item.ThreeLevel" :key="dtal.goodTypeId" class="dl" @click="golist(dtal)">
              <div class="dt">
                <imgLoading :src="dtal.goodTypeImg" />
              </div>
              <div class="dd">{{ dtal.goodTypeName }}</div>
            </div>
          </div>
        </div>
      </div>
    </treeSelect>

  </div>
</template>

<script>
import treeSelect from '@/component/treeSelect'
import search from '@/component/head/search'
import { getkoalalist, gettwokoalalist } from '@/services/api'
import imgLoading from '@/component/imgLoading'

export default {
  name: 'Koala',
  components: {
    search,
    treeSelect,
    imgLoading
  },
  data() {
    // 这里存放数据
    return {
      brand: [],
      // 头部需要的参数
      searchOut: {
        isShowSearch: true, // 现在是出现搜索
        bgColor: '#ffffff', // 设置导航背景颜色
        titleData: '', // 设置标题 title 名字
        urlImg: '', // 点击返回图片 可以设置
        isSearch: '', // 搜索框 input val 值
        placeholder: '搜索商品', // 搜索框 input placeholder 值
        isShowReturn: true, // 点击返回图片 显隐
        callbackGo: this.callbackGo, // 返回回调函数
        callbackFocus: this.callbackDown
      },
      pddOptions: {
        active: 0,
        items: [],
        clickNav: this.changePddTab,
        list: [],
        goodsParams: {
          id: '',
          page: 1,
          pageSize: 10,
          loading: false,
          finished: false,
          error: false,
          changePage: this.changePage
        }
      }
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    const list = []
    getkoalalist({
      'channelId': '6'
    }).then(res => {
      this.brand = res.brand
      this.pddOptions.list = res.data.typeTwo
      res.data.typeFirst.forEach(ele => {
        list.push({
          text: ele.goodTypeName,
          goodTypeId: ele.goodTypeId
        })
      })
    })
    this.pddOptions.items = list
  },
  beforeCreate() {
  }, // 生命周期 - 创建之前
  beforeMount() {
  }, // 生命周期 - 挂载之前
  beforeUpdate() {
  }, // 生命周期 - 更新之前
  updated() {
  }, // 生命周期 - 更新之后
  beforeDestroy() {
  }, // 生命周期 - 销毁之前
  destroyed() {
  }, // 生命周期 - 销毁完成
  activated() {
  },
  // 方法集合
  methods: {
    callbackGo() {
      this.$router.go(-1)
    },
    changePddTab(e) {
      const goodTypeId = this.pddOptions.items[e].goodTypeId
      gettwokoalalist({
        'goodsTypeId': goodTypeId, // 一级分类ID
        'channelId': '6'
      }).then(res => {
        this.brand = res.brand
        this.pddOptions.list = res.data
      })
    },
    golist(dtal) {
      this.$router.push({
        path: 'shopclassify',
        query: {
          'keyword': dtal.goodTypeName,
          'goodTypeId': dtal.goodTypeId
        }
      })
    },
    golists(dtal) {
      this.$router.push({
        path: 'shopclassify',
        query: {
          'keyword': dtal.brandName,
          'goodTypeId': dtal.brandId,
          'brand': 1
        }
      })
    },
    callbackDown() {
      this.$router.push({
        path: 'goodsSearch'
      })
    }
  } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="less" scoped>
* {
  text-align: left;
  margin: 0;
}

/deep/ .van-tree-select__nav {
  background: #ecebeb;
}

/deep/ .van-tree-select__content {
  background: #f6f6f6;;
}

h1 {
  font-size: 14px;
  //height: 20px;
  line-height: 20px;
  background: #ffffff;
}

.right_box_all {
  background: #f6f6f6;;
  padding: 0px 0px 0px 10px;
  box-sizing: border-box;

  .titlebox {
    width: 100%;
    background: #f8f8f8;
    overflow: hidden;
    padding: 12px 0 8px 0;
    box-sizing: border-box;

    h1 {
      border-bottom: 1px solid #f2f2f2;
      padding: 8px 0 8px 8px;
      box-sizing: border-box;
    }

    .imgbox {
      width: 86px;
      height: 80px;
      float: left;
      margin-left: 1px;
      background: #ffffff;
      position: relative;
      margin-top: 1px;

      img {
        width: 100%;
        height: auto;
        padding: 4px;
        box-sizing: border-box;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.dtalbox {
  width: 100%;
  background: #ffffff;

  h1 {
    padding: 7px 0 7px 8px;
    box-sizing: border-box;
    font-weight: 700;
  }

  .div {
    display: flex;
    flex-wrap: wrap;

    .dl {
      width: 32%;
      height: 120px;
      margin-left: 1%;
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;

      .dd {
        width: 100%;
        height: 40px;
        font-size: 12px;
        text-align: center;
        margin-top: 6px;
      }
    }
  }

}
</style>
