import request from '@/services/request'

/**
 * 根据code码获取用户信息
 * */
export function getCodeByUserInfo(data) {
  return request({
    url: '/h5/openWebSite/getCodeByUserInfo',
    method: 'post',
    data,
    prefix: ''
  })
}
// 爆款拼团列表
export function assembleList(data) {
  return request({
    url: '/hd/assembleList',
    method: 'get',
    params: data,
    prefix: '',
    headers: {
      hideLoading: true
    }
  })
}
/**
 * 首页/顶部分类
 * */
export function getIndexHeadType(data) {
  return request({
    url: '/stall/H5Index',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

/**
 * 首页/秒杀
 * */
export function miaosha(data) {
  return request({
    url: '/stall/getFlashGoodsList',
    method: 'post',
    data,
    headers: {
      hideLoading: true
    },
    prefix: 'shopyuelvhui'
  })
}

/**
 * 首页/惊喜不断
 * */
export function jingxi(data) {
  return request({
    url: '/stall/getJDSalesVolumeGoodsList',
    method: 'post',
    data,
    prefix: 'shopyuelvhui',
    headers: {
      hideLoading: true
    }

  })
}

/**
 * 首页/拼团
 * */
export function pintuan(data) {
  return request({
    url: '/stall/channelGoodsList',
    method: 'post',
    data,
    headers: {
      hideLoading: true
    },
    prefix: 'shopyuelvhui'
  })
}

/**
 * 首页/都买了
 * */
export function allbuy(data) {
  return request({
    url: '/stall/getToppingGoodsList',
    method: 'post',
    data,
    prefix: 'shopyuelvhui',
    headers: {
      hideLoading: true
    }
  })
}

/**
 * 首页/新人专属推荐
 * */
export function newpeople(data) {
  return request({
    url: '/stall/indexNewPeopleGoodsList',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

/**
 * /jd/ 京东联盟数据列表
 * */
export function jdHomeIndex(data) {
  return request({
    url: '/outside/jdHomeIndex',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

/**
 * /jd/ 京东联盟--品类商品列表
 * */
export function jdHomeSearch(data) {
  return request({
    url: '/outside/jdHomeSearchGoodsList',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

/**
 * 高佣产品
 * */
export function highShare(data) {
  return request({
    url: '/stall/getHighShare',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

/**
 * /pdd/ 拼多多联盟数据列表
 * */
export function pddHomeIndex(data) {
  return request({
    url: '/outside/pddHomeIndex',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

/**
 * 直播banner
 * */
export function videobanner(data) {
  return request({
    url: '/app/live/getLiveBanner',
    method: 'post',
    data,
    prefix: 'yuelvhui'
  })
}
// 空白页请求接口
export function kbgetLive(data) {
  return request({
    url: '/app/third/authOperateForMobileWeb',
    method: 'post',
    data,
    prefix: 'yuelvhui'
  })
}
/**
 * /jd/ 拼多多--分类商品列表
 * */
export function pddHomeSearch(data) {
  return request({
    url: '/outside/pdd/v1/queryGoodsList',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

/**
 * 直播列表
 * */
export function videolist(data) {
  return request({
    url: '/app/live/getContentList',
    method: 'post',
    data,
    prefix: 'yuelvhui'
  })
}

/**
 * /jd/ 京东联盟--首页搜索
 * */
export function jdIndexSearch(data) {
  return request({
    url: '/stall/jd/searchGoodsList',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

/**
 * /搜索历史信息&&商品搜索页信息
 * */
export function goodsSearchHistory(data) {
  return request({
    url: '/mall/getNewKeyWords2?',
    method: 'get',
    data,
    prefix: 'shopyuelvhui'
  })
}
export function suggestion(data) {
  return request({
    url: '/mall/search/v2/suggestion',
    method: 'post',
    data,
    headers: {
      hideLoading: true
    },
    hideMessage: true,
    prefix: 'shopyuelvhui'
  })
}

/**
 * /首页搜索
 * */
export function newEndSearchData(data) {
  return request({
    url: '/mall/search/v2/keyword/newEndSearchData3',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

/**
 * 食品生鲜分类详情
 * */
export function freshSearch(data) {
  return request({
    url: '/mall/search/v2/keyword',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

/**
 * 京东商品搜索
 * */
export function jdSearch(data) {
  return request({
    url: '/stall/jd/searchGoodsList',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

/**
 * 拼多多商品搜索
 * */
export function pddSearch(data) {
  return request({
    url: '/stall/pdd/searchGoodsList',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

/**
 * 点击淘宝商品详情判断授权
 * */
export function switchTaoBaoAuth(data) {
  return request({
    url: '/outside/taobk/v1/checkJurisdiction',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}
/**
 * 判断用户身份
 * */
export function getUserShenFen(data) {
  return request({
    url: '/stall/isUserIdentity',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

/**
 * 空白页面判断有没有登录
 */
export function getJumpUrlNew(data) {
  return request({
    url: '/mall/V3/getJumpUrlNew',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}
// 首页 新人进度弹窗
export function popProgress(data) {
  return request({
    url: '/app/popProgress',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

// 弹出优先级接口
export function ejectPriorityV2(data) {
  return request({
    url: '/app/ejectPriorityV2',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

// 中台商品ID转成悦淘

export function getGoodsInfo(data) {
  return request({
    url: '/mall/getGoodsInfo',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}
// jd 详情 list
export function jdListAll(data) {
  return request({
    url: '/outside/jdHomeEliteGoodsList',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}
// //jd 详情 list
// export function jdListDetails(data) {
//     return request({
//         url: '/outside/jdGoodsInfo',
//         method: 'post',
//         data,
//         prefix: 'shopyuelvhui'
//     })
// }

// 京东特价
export function getpreferential(data) {
  return request({
    url: 'mall/preferential?',
    method: 'get',
    params: data,
    prefix: 'shopyuelvhui'
  })
}
// 爆款比买
export function mustBuy(data) {
  return request({
    url: '/mall/V2/mustBuyNew ',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}
// 考拉海购列表

export function getkoalalist(data) {
  return request({
    url: '/mall/jdTypeList',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}
// 考拉海购获取二级列表
export function gettwokoalalist(data) {
  return request({
    url: '/mall/jdTypeLevel',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

// 拼团商城
export function assembleGoods(data) {
  return request({
    url: '/hd/assembleGoods',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}
// 考拉跳转之后展示的列表
export function optionskeyword(data) {
  return request({
    url: '/mall/search/v2/keyword',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}
// 我的拼团
export function myNewAssembleList(data) {
  return request({
    url: '/hd/myNewAssembleList',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}
// 考拉跳转之后筛选
export function getscreen(data) {
  return request({
    url: '/mall/search/v2/screen/',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}
// 我的拼团详情list
export function assembleData(data) {
  return request({
    url: '/hd/assemble',
    method: 'get',
    params: data,
    prefix: 'shopyuelvhui'
  })
}
// 我的拼团详情开团
export function assembleDetail(data) {
  return request({
    url: '/hd/assembleDetail',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

// 我的拼团详情 推荐
export function recommendGoods(data) {
  return request({
    url: '/mall/V2/recommendGoods',
    method: 'post',
    params: data,
    prefix: 'shopyuelvhui',
    headers: {
      hideLoading: true
    }
  })
}

// 我的限时秒杀
export function flashDetail(data) {
  return request({
    url: '/mall/flashDetail',
    method: 'post',
    params: data,
    prefix: 'shopyuelvhui'
  })
}
// 我的限时秒杀  评论
export function productCommentList(data) {
  return request({
    url: '/mall/productCommentList',
    method: 'post',
    params: data,
    prefix: 'shopyuelvhui'
  })
}
//
export function getProductSkuDatail(data) {
  return request({
    url: '/mall/getProductSkuDatail',
    method: 'post',
    params: data,
    prefix: 'shopyuelvhui'
  })
}

// 首页 tab
export function newIndexHome(data) {
  return request({
    url: '/mall/V4/newIndexHome',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}
// 首页/活动
export function homeActivity(data) {
  return request({
    url: '/mall/V3/homeActivity',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}
// 首页 秒杀
export function indexFlashGoodsShow(data) {
  return request({
    url: '/mall/V4/indexFlashGoodsShow',
    method: 'get',
    params: data,
    prefix: 'shopyuelvhui'
  })
}

// 首页 排名
export function secondIndexActivity(data) {
  return request({
    url: '/mall/V4/secondIndexActivity',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}
// 首页 list
export function getCpsSelfActiveList(data) {
  return request({
    url: '/mall/activity/getCpsSelfActiveList',
    method: 'post',
    data,
    prefix: 'shopyuelvhui',
    headers: {
      hideLoading: true
    }
  })
}

/**
 * 首页cps爆款列表
 * 2021-06-07 22:08:33
 * @author SnowRock
 */
export function fetchCpsListBySelling(data) {
  return request({
    url: '/cpsreforge/jd/jdActivityGoods',
    method: 'post',
    data,
    prefix: 'shopyuelvhui',
    headers: {
      hideLoading: true
    }
  })
}

// 首页 悦淘特价
export function getSpecialOffer(data) {
  return request({
    url: '/mall/getSpecialOffer',
    method: 'post',
    data,
    prefix: 'shopyuelvhui',
    headers: {
      hideLoading: true
    }
  })
}
export function FlashGoods(data) {
  return request({
    url: '/mall/V2/FlashGoods',
    method: 'post',
    data,
    prefix: 'shopyuelvhui',
    headers: {
      hideLoading: true
    }
  })
}

export function flashActivity(data) {
  return request({
    url: '/mall/V2/flashActivity',
    method: 'post',
    data,
    prefix: 'shopyuelvhui',
    headers: {
      hideLoading: true
    }
  })
}

// 排名榜 tab
export function queryRankingMenu(data) {
  return request({
    url: '/taobk/v1/queryRankingMenu',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

// 排名榜list
export function queryRankingList(data) {
  return request({
    url: '/taobk/v1/queryRankingList',
    method: 'post',
    data,
    prefix: 'shopyuelvhui',
    headers: {
      hideLoading: true
    }
  })
}

// 爆品 拼团
export function assemble(data) {
  return request({
    url: 'hd/assemble',
    method: 'get',
    params: data,
    prefix: 'shopyuelvhui'
  })
}
export function halfPrice(data) {
  return request({
    url: '/mall/halfPriceNew',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}
// 个护
export function getActivityCategoryInfo(data) {
  return request({
    url: '/mall/V3/getActivityCategoryInfo',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}
export function newActivityList(data) {
  return request({
    url: 'mall/V3/newActivityList',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

// 分类

export function getSelfCategoryFirstLevel(data) {
  return request({
    url: '/mall/selfSupport/getSelfCategoryFirstLevel',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}
// 悦淘国际 LIST
export function getXyHomeData(data) {
  return request({
    url: '/mall/getXyHomeData',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}
// 悦淘国际 LIST 下边
export function getXyHomeGoods(data) {
  return request({
    url: '/mall/getXyHomeGoods ',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}
// 悦淘国际/品牌
export function getBrands(data) {
  return request({
    url: '/mall/getBrands',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

// 悦淘国际/品牌 二级 list
export function getXySearch(data) {
  return request({
    url: '/mall/getXySearch',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

// 悦淘国际/更多 list
export function getXyCateGoods(data) {
  return request({
    url: '/mall/getXyCateGoods',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

// 数码家电 list
export function recommendList(data) {
  return request({
    url: '/mall/V2/recommendList',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

// 数码家电 公用
export function newCategoryList(data) {
  return request({
    url: '/mall/V2/newCategoryList',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

// 开发票
export function invoiceInfo(data) {
  return request({
    url: '/app/member/invoice/info',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

export function getList(data) {
  return request({
    url: '/app/member/invoice/getList',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

export function save(data) {
  return request({
    url: '/app/member/invoice/save',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}
// 获取关键词搜索关键词
export function getChannelSearchWord(data) {
  return request({
    url: '/mall/search/v2/getChannelSearchWord',
    method: 'get',
    params: data,
    prefix: '',
    headers: {
      hideLoading: true
    }
  })
}

/**
 * 获取用户是否授权pdd
 * 2021-05-17 15:10:56
 * @author SnowRock
 */
export function fetchPddMemberAuthority(uid) {
  return request({
    url: '/outside/pddMemberAuthorityQuery',
    method: 'post',
    params: {
      uid
    },
    prefix: ''
  })
}

/**
 * 生成营销工具推广链接
 * @param uid 用户id
 * @param channel_type -1-活动列表，0-默认红包，2–新人红包，3-刮刮卡，5-员工内购，6-购物车，7-大促会场，8-直播间列表集合页，10-生成绑定备案链接（授权链接）
 * @returns {*}
 */
export function fetchPddRpPromUrlGenerate(uid, channel_type) {
  return request({
    url: '/outside/pddRpPromUrlGenerate',
    method: 'post',
    params: {
      uid, channel_type
    },
    prefix: ''
  })
}

export function fetchBanner(params) {
  return request({
    url: '/product/getBanner',
    method: 'post',
    data: params,
    prefix: ''
  })
}
